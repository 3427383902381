<template>
   <div id="box">
      <!-- 单独的量表评估报告 -->
      <h1>光照治疗报告</h1>
      <h2>重庆市精神卫生中心</h2>
      <table>
         <tr>
            <td valign="center"
                rowspan="1"
                colspan="4"
                style="text-align: justify"
                class="inputDeep">
               姓名：{{ this.update[0].cname }}
            </td>
            <td valign="center"
                rowspan="1"
                colspan="4"
                style="text-align: justify">
               就诊卡号：{{ this.update[0].cid }}
            </td>
         </tr>
         <tr>
            <td valign="center"
                rowspan="1"
                colspan="4"
                style="text-align: justify">

               科室：{{ update[0].department }}<input type="text">
            </td>
            <td valign="center"
                rowspan="1"
                colspan="4"
                style="text-align: justify">
               指导医生：{{ update[0].doctor }}<input type="text">
            </td>
         </tr>
         <tr>
            <td valign="center"
                rowspan="1"
                colspan="10"
                style="text-align: center">
               报告时间段：{{ this.time[0] |formatDate }} — {{ this.time[this.time.length-1] |formatDate }}
            </td>
         </tr>
         <tr>
            <td rowspan="1"
                colspan="10"
                id="jieshao"
                valign="top"
                style="font-size: 18px;">
               报告简介：在光照治疗过程中，模拟太阳光谱的自然光线进入人体眼睛中，会被视网膜上的第三种感光细胞感受到，释放神经递质，参与调节人体血清素、去甲肾上腺素以及褪黑激素等的分泌，从而影响生物节律，改善情绪、睡眠、认知和行为。<br />
            </td>
         </tr>
         <tr>
            <td valign="center"
                rowspan="1"
                colspan="1"
                style="text-align: center">
               序号
            </td>
            <td valign="center"
                rowspan="1"
                colspan="2"
                style="text-align: center">
               报告类型
            </td>
            <td valign="center"
                rowspan="1"
                colspan="2"
                style="text-align: center">
               光照强度
            </td>
            <td valign="center"
                rowspan="1"
                colspan="2"
                style="text-align: center">
               治疗时长
            </td>
            <td valign="center"
                rowspan="1"
                colspan="2"
                style="text-align: center">
               日期
            </td>
         </tr>
         <template v-for="(item, index) in update">
            <tr :key="index">
               <td valign="center"
                   rowspan="1"
                   colspan="1"
                   style="text-align: center;">
                  {{ index + 1 }}
               </td>
               <td valign="center"
                   rowspan="1"
                   colspan="2"
                   style="text-align: center">
                  {{ item.rtName }}
               </td>
               <td valign="center"
                   rowspan="1"
                   colspan="2"
                   style="text-align: center">
                  {{ item.data.split(",")[1] + "lux" }}
               </td>
               <td valign="center"
                   rowspan="1"
                   colspan="2"
                   style="text-align: center">
                  {{ item.data.split(",")[0] + "min" }}
               </td>
               <td valign="center"
                   rowspan="1"
                   colspan="2"
                   style="text-align: center">
                  {{ item.dateTime|formatDate }}
               </td>
            </tr>
         </template>
      </table>

      <table id="table2">
         <div style="text-align: left">
            <textarea>测验结论：{{update[0].review}}</textarea>
         </div>
      </table>
      <table id="table3">
         <tr style="height: 40px">
            <td valign="left"
                rowspan="1"
                colspan="4"
                style="text-align: rigth; border: none">
               _______年_______月_______日填
            </td>
            <td valign="center"
                rowspan="1"
                colspan="4"
                style="text-align: right; border: none">
               签字：________<br />
            </td>
         </tr>
      </table>

      <p style="text-align: left; color: black">
         当前时间：{{ this.updateTime }}
      </p>
   </div>
</template>

<script>
import { formatDate } from "../../common/date"; // 在组件中引用date.js
export default {
   name: "Dashboard",
   data () {
      return {
         update: [],
         time: "",
         cname: "",
         CID: "",
         updateTime: "",
         rDate: ""
      };
   },
   created () {

      this.getData();

   },

   mounted () {
      this.addDate();

   },

   computed: {},
   filters: {
      formatDate (time) {
         let date = new Date(time);
         return formatDate(date, "yyyy-MM-dd-hh:mm:ss");
      },
   },
   methods: {

      getData () {
         const update = localStorage.getItem("arrdata");
         this.update = JSON.parse(update);
         let arr = this.update.map((item) => item.dateTime);
         this.time = arr.sort();

      },
      //获取当前年月日
      addDate () {
         const nowDate = new Date();
         const date = {
            year: nowDate.getFullYear(),
            month: nowDate.getMonth() + 1,
            date: nowDate.getDate(),
         };
         const newmonth = date.month > 10 ? date.month : "0" + date.month;
         const day = date.date > 10 ? date.date : "0" + date.date;
         this.updateTime = date.year + "-" + newmonth + "-" + day;
      },
   },
};
</script>

<style lang="scss" scoped>
#box {
   width: 800px;
   margin: auto;
}
h1 {
   color: black;
}
h2 {
   color: black;
}
table {
   border: 1px solid black;
   border-collapse: collapse;
   margin: 0 auto;
   width: 800px;
}
table td,
th {
   border: 1px black solid;
   background-color: white;
   height: 40px;
   width: 100px;
   color: black;
}
#table2 {
   width: 798px;
   height: 200px;
   border-top: none;
   div {
      width: 798px;
      height: 200px;
      textarea {
         // padding: 0;
         margin: 0px;

         color: rgba(10, 10, 10, 0.918);
         font-size: 16px;
         height: 100%;
         width: 100%;
         outline: none;
         border: none;
         resize: none;
      }
   }
}
#table3 {
   border-top: none;
   color: black;
   // text-align:justify
}

#jieshao {
   // line-height: 0px;
   word-wrap: break-word;
   word-break: break-all;
   padding: 0;
   width: 800px;
   text-align: left;
   height: 200px;
   color: black;
}
input {
   border: none;
   width: 60px;
   outline: none;
}
</style>
